@font-face {
  font-family: "ColateraSoft";
  src: url("../assets/font/spiegel.otf");
}

.autocomplete-input input {
    color: white;
    font-size: x-large;
    margin-left:0.5em;
    font-family:"ColateraSoft", sans-serif;
    font-weight: bold;  
  }

  
  .autocomplete-input label {
    color: white;
    margin-left:0.5em;
    font-weight: bold;
  }
