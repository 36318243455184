@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Cavilant";
  src: url("./assets/font/Cavilant.otf");
}

@font-face {
  font-family: "ColateraSoft";
  src: url("./assets/font/ColateraSoft.ttf");
}

@font-face {
  font-family: "BlueHighway";
  src: url("./assets/font/blueHighway.ttf");
}

@font-face {
  font-family: "Heavitas";
  src: url("./assets/font/Heavitas.ttf");
}

@font-face {
  font-family: "Spiegel";
  src: url("./assets/font/spiegel.otf");
}

@font-face {
  font-family: "Spiegel_bold";
  src: url("./assets/font/Spiegel_bold.otf");
}

.font-quizz {
  font-family: 'Fascinate', cursive;
}

.font-title {
  font-family: 'Cavilant', sans-serif;
}

.font-text2 {
  font-family: 'BlueHighway', sans-serif;
}

.font-spegiel {
  font-family: 'Spiegel', sans-serif;
}
.font-spegiel-bold {
  font-family: 'Spiegel_bold', sans-serif;
}

.font-spegiel-bold-nav {
  font-family: 'Spiegel_bold', sans-serif;
  font-size: 1.5rem !important;
}

.font-texxt {
  font-family: 'ColateraSoft', sans-serif;
}

.font-card-title {
  font-family: 'Heavitas', sans-serif;
}

.loader{
  display: inline-block;
  text-align: center;
  line-height: 86px;
  text-align: center;
  position: relative;
  padding: 0 48px;
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  }
  .loader:before, .loader:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: currentColor;
    position: absolute;
    animation: load .7s infinite alternate ease-in-out;
    top: 0;
  }
  .loader:after {
    top: auto;
    bottom: 0;
  }
@keyframes load {
  0% {
    left: 0;
    height: 43px;
    width: 15px;
    transform: translateX(0)
  }
  50% {
    height: 10px;
    width: 40px
  }
  100% {
    left: 100%;
    height: 43px;
    width: 15px;
    transform: translateX(-100%)
  }
}

/* Nina */
.button--nina {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.button--nina > span {
  display: inline-block;
  padding: 1em 0;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina:hover::before {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button--nina:hover > span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button--nina:hover > span:nth-child(1) {
  -webkit-transition-delay: 0.045s;
  transition-delay: 0.045s;
}
.button--nina:hover > span:nth-child(2) {
  -webkit-transition-delay: 0.09s;
  transition-delay: 0.09s;
}
.button--nina:hover > span:nth-child(3) {
  -webkit-transition-delay: 0.135s;
  transition-delay: 0.135s;
}
.button--nina:hover > span:nth-child(4) {
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}
.button--nina:hover > span:nth-child(5) {
  -webkit-transition-delay: 0.225s;
  transition-delay: 0.225s;
}
.button--nina:hover > span:nth-child(6) {
  -webkit-transition-delay: 0.27s;
  transition-delay: 0.27s;
}
.button--nina:hover > span:nth-child(7) {
  -webkit-transition-delay: 0.315s;
  transition-delay: 0.315s;
}
.button--nina:hover > span:nth-child(8) {
  -webkit-transition-delay: 0.36s;
  transition-delay: 0.36s;
}
.button--nina:hover > span:nth-child(9) {
  -webkit-transition-delay: 0.405s;
  transition-delay: 0.405s;
}
.button--nina:hover > span:nth-child(10) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}
.button--nina:hover > span:nth-child(11) {
  -webkit-transition-delay: 0.495s;
  transition-delay: 0.495s;
}
.button--nina:hover > span:nth-child(12) {
  -webkit-transition-delay: 0.54s;
  transition-delay: 0.54s;
}
.button--nina:hover > span:nth-child(13) {
  -webkit-transition-delay: 0.585s;
  transition-delay: 0.585s;
}
.button--nina:hover > span:nth-child(14) {
  -webkit-transition-delay: 0.63s;
  transition-delay: 0.63s;
}
.button--nina:hover > span:nth-child(15) {
  -webkit-transition-delay: 0.675s;
  transition-delay: 0.675s;
}
.button--nina:hover > span:nth-child(16) {
  -webkit-transition-delay: 0.72s;
  transition-delay: 0.72s;
}
.button--nina:hover > span:nth-child(17) {
  -webkit-transition-delay: 0.765s;
  transition-delay: 0.765s;
}
.button--nina:hover > span:nth-child(18) {
  -webkit-transition-delay: 0.81s;
  transition-delay: 0.81s;
}
.button--nina:hover > span:nth-child(19) {
  -webkit-transition-delay: 0.855s;
  transition-delay: 0.855s;
}
.button--nina:hover > span:nth-child(20) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}