/* Popup style */
.popup-box {
    position: fixed;
    background: #21202079;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 75%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh - 20px);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid rgb(219, 188, 188);
    overflow: auto;
  }