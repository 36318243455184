
.slider {
    -webkit-appearance: none;
    width: 80%;
    height: 100%;
    background: #ffffff;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

    @media screen and (min-width: 768px) {
      .grid-cols-pannel {
        grid-template-columns: repeat(27, minmax(0, 1fr));
      }
    }
  
    @media screen and (max-width: 768px) {
      .grid-cols-pannel {
        grid-template-columns: repeat(15, minmax(0, 1fr));
      }
    }

  .grid-cols-recap {
      grid-template-columns: repeat(18, minmax(0, 1fr));	  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #060606;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #000000;
    cursor: pointer;
  }

  .background-animate {
    background-size: 400%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
  }

  @keyframes AnimationName {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #d46a6a;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
  }